// Main
import { main_coins_low } from '../backtestData/portfolios/main_coins_low';
import { main_coins_medium } from '../backtestData/portfolios/main_coins_medium';
import { main_coins_high } from '../backtestData/portfolios/main_coins_high';
// Mixed
import { mixed_coins_low } from '../backtestData/portfolios/mixed_coins_low';
import { mixed_coins_medium } from '../backtestData/portfolios/mixed_coins_medium';
import { mixed_coins_high } from '../backtestData/portfolios/mixed_coins_high';
// Exclusive
import { exclusive_coins_low } from '../backtestData/portfolios/exclusive_coins_low';
import { exclusive_coins_medium } from '../backtestData/portfolios/exclusive_coins_medium';
import { exclusive_coins_high } from '../backtestData/portfolios/exclusive_coins_high';

//other imports
import { delistedCoins } from 'services/coins/common';

const excludeDelistedCoins = coins => {
  return coins.filter(coin => !delistedCoins.includes(coin));
};

export const portfolioList = [
  {
    id: '6',
    name: 'Market Leaders',
    riskLevels: [1, 5, 10],
    backendMinLevels: [9.0, 8.25, 7.5],
    coins: excludeDelistedCoins(['BTC', 'ETH', 'SOL', 'USDC']),
    quote: 'USDC',
    bgStyle: 'linear-gradient(45deg, #014552, #001835)',
    backtestData: [
      exclusive_coins_low,
      exclusive_coins_medium,
      exclusive_coins_high
    ]
  },
  {
    id: '7',
    name: 'High Volume',
    riskLevels: [1, 5, 10],
    backendMinLevels: [9.0, 8.25, 7.5],
    coins: excludeDelistedCoins(['BTC', 'ETH', 'BNB', 'SOL', 'XRP', 'USDC']),
    quote: 'USDC',
    bgStyle: 'linear-gradient(45deg, #3c5201, #001835)',
    backtestData: [main_coins_low, main_coins_medium, main_coins_high],
    topPerformer: true
  },
  {
    id: '8',
    name: 'Discovery Altcoins',
    riskLevels: [1, 5, 10],
    backendMinLevels: [9.0, 8.25, 7.5],
    coins: excludeDelistedCoins([
      'BTC',
      'ETH',
      'BNB',
      'SOL',
      'XRP',
      'ADA',
      'DOGE',
      'LINK',
      'DOT',
      'AVAX',
      'LTC',
      'SHIB',
      'POL',
      'UNI',
      'USDC'
    ]),
    quote: 'USDC',
    bgStyle: 'linear-gradient(45deg, #520151, #001835)',
    backtestData: [mixed_coins_low, mixed_coins_medium, mixed_coins_high]
  }
];

export const defaultPortfolio = {
  id: 'default',
  name: 'Default',
  riskLevels: [1, 5, 10],
  backendMinLevels: [9.0, 8.25, 7.5],
  coins: excludeDelistedCoins([
    'BTC',
    'ETH',
    'BNB',
    'SOL',
    'XRP',
    'ADA',
    'DOGE',
    'LINK',
    'DOT',
    'AVAX',
    'LTC',
    'SHIB',
    'POL',
    'UNI',
    'USDC'
  ]),
  quote: 'USDC',
  bgStyle: 'linear-gradient(45deg,rgb(67, 48, 236), #001835)'
};
